/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import HTHomePage from "views/HTHomePage/HTHomePage.js";
import HTContactPage from "views/HTContactPage/HTContact.js";
import HTSupportPage from "views/HTSupportPage/HTSupportPage.js";
import HTPrivacyPolicyPage from "views/HTPrivacyPage/HTPrivacyPolicy.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/home" component={HTHomePage}/>
      <Route path="/support-us" component={HTSupportPage}/>
      <Route path="/privacy-policy" component={HTPrivacyPolicyPage}/>
      <Route path="/support" component={HTContactPage}/>
      <Redirect exact from="/" to="/home"/>
      <Route path="/error" component={ErrorPage} />
      <Route path="*" component={ErrorPage}/>
    </Switch>
  </Router>,
  document.getElementById("root")
);
