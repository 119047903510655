import React from "react";
// @material-ui/core components
import { Jumbotron, Button, Row, Col } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Code from "@material-ui/icons/Code";
import People from "@material-ui/icons/People";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import introStyle from "assets/jss/material-kit-pro-react/views/htHomePageSections/introStyle.js";

const useStyles = makeStyles(introStyle);

export default function SectionIntro() {
  const classes = useStyles();
  const pTagStyle = {
    fontSize: "1.2rem",
    color: "black",
    textAlign: "left"
  };

  const h2TagStyle = {
    color: "black",
    fontWeight: "300"
  }

  const liTagStyle = {
    fontSize: "1rem",
    color: "black"
  };

  return (
    <div className={classes.section}>
      <Jumbotron>
      
        <p style={pTagStyle}>
          HTechStudio LLC and its affiliates (“1HPGames, "us", "our" or "we") are dedicated to protecting
          the privacy rights of our users ("users" or "you"). This Privacy Policy (the "Policy")describes the ways we
          collect, store, use, and manage the information, including personal information, that you provide or we collect
          in connection with our mobile applications and websites, including www.HTechStudio.com (the "Site" or “Sites”)
          or any mobile applicationprovided on a mobile platform (for example, iOS and Android)(collectively, the "Service").
          Please note that the scope of this Policy is limited to information collected or received by HTechStudio LLC through
          your use of the Service.  HTechStudio LLC is not responsible for the actions of third party people or companies,
          the content of their sites, the useof information you provide to them, or any products or services they may offer.
          Any link to thosesites does not constitute our sponsorship of, or affiliation with, those people or companies.By using the
          Service, you are expressing your agreement to this Policy and the processing of your data, including your personal information,
          in the manner provided in this Policy. If you do not agree to these terms, please do not use the Service.
            </p>
        <p style={pTagStyle} >
          If you have questions or
          concerns about our privacy policy or practices, please contact us at support@HTechStudio.com.
            </p>

        <h2 style={h2TagStyle} className="display-4">Information Collection and Use</h2>
        <hr className="my-2" />
        <p style={pTagStyle} >
          HTechStudio LLC collects information as described below.
          Our primary goals in collecting and using information is to create your account,
          provide Services to you, improve our Service, contact you, conduct research and
          create reports for internal use.  We store information onservers located in the
          United States via Firebase and/or Heroku and may store information on servers and
          equipment in other countries.
            </p>
        <p style={pTagStyle} >
          In order to use your community forum, you must register
          and provide a username, passwordand email address. You may also register by authenticating
          using Google, Facebook, or Applecredentials if those options are made available to you.
            </p>

        <h2 style={h2TagStyle} className="display-4">HTechStudio LLC Websites</h2>
        <hr className="my-2" />
        <p style={pTagStyle} >
          While you are browsing our Sites, your computer's operating system,
          Internet Protocol (IP) address, access times, browser type and language
          and referring Web site addresses may belogged automatically. We may use
          this information to monitor, develop and analyze your use of the Service.
          In addition, we may ask you to submit and we may process data that is
          personal toyou, including but not limited to your name, profile photo,
          gender, age or birthday, links to your profiles on social networking
          websites and other third party sites, user names and e-mail and mail addresses.
            </p>

        <h2 style={h2TagStyle} className="display-4">Forums/Private Messages</h2>
        <hr className="my-2" />
        <p style={pTagStyle} >
          Our Web site offers publicly accessible blogs, private messages, or community
          forums. You should be aware that any information you provide in these areas may
          be read, collected, and used by others who access them. To request removal of your
          personal information from our blog or community forum, contact us at support@HTechStudio.com.
          In some cases, we may not be able to remove your personal information, in which case we will
          let you know if weare unable to do so and why.
            </p>
        <p style={pTagStyle} >
          If you choose to use Google, Facebook, or Apple to connect to use our blog or
          community forums, the information you provide is tied to your Facebook account.
          You will need to contact the respective service directly in order to
          request removal of personal information from our blog or community forum.
            </p>

        <h2 style={h2TagStyle} className="display-4">Social Media (Features) and Widgets</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          Our Web site includes Social Media Features, such as Like buttons, Widgets,
          such as the Share this button, or interactive mini-programs that run on our site.
          These Features may collect your IP address, which page you are visiting on our site,
          and may set a cookie to enable the Feature to function properly. Social Media Features
          and Widgets are either hosted by a third party or hosted directly on our Site. Your
          interactions with these Features are governed by the privacy policy of the company providing it.
            </p>

        <h2 style={h2TagStyle} className="display-4">iOS and Android Platforms</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          When you use any of the HTechStudio LLC applications on a mobile platform,
          we may collect and record certain information such as your unique device ID (persistent /non-persistent),
          hardware type, media access control ("MAC") address, international mobile equipment identity ("IMEI"),
          the version of your operating system ("OS"), your device name, your email address (if you have connected
          to Facebook, Google+, or Apple), and your location (based on your Internet Protocol ("IP") address).
          This information is useful to us for troubleshooting and helps us understand usage trends.
            </p>
        <p style={pTagStyle}>
          We may collect your location based information for the purpose of providing you with the correct version of the application
          and so you may interact with other users of the application.  We will not share this information with any
          third party without your permission. If you no longer wish to allow us to track or use this information, you may turn it off at the device level.
          Please note, the application may not work properly if we are unable to tell where you are coming from (Countryspecific).
            </p>
        <p style={pTagStyle} >
          In addition, we create a unique user ID to track your use of our Service.
          This unique user ID is stored in connection with your profile information
          to track the HTechStudio LLC applicationyou are using.
            </p>

        <h2 style={h2TagStyle} className="display-4">Facebook Connect, Game Center, Google+, and Apple Sign in</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          You can log in to our site using sign-in services ("SN Service") such as
          Facebook Connect, orApple's Game Center. These services will authenticate
          your identity and provide you the optionto share certain personal information
          with us such as your name, email address, and resume topre-populate our sign up form.
          Services like Facebook Connect give you the option to post information about your
          activities on this Web site to your profile page to share with others within your network.
            </p>
        <p style={pTagStyle}>
          We may collect and record information through the SN Service in accordance with
          the policiesand terms of that SN Service. The information we collect when you
          connect your user accountto an SN Service may include: (1) your name, (2) your
          SN Service user identification number and/or user name, (3) locale, city, state and
          country, (4) sex, (5) birth date, (6) email address,(7) profile picture or its URL,
          and (8) the SN Service user identification numbers for your friends that are also
          connected to our application(s).
            </p>
        <p style={pTagStyle}>
          If you want us to delete the data we receive from Facebook, Game Center or Google+
          aboutyou, please contact us at support@HTechStudio.com.
            </p>

        <h2 style={h2TagStyle} className="display-4">Invite a Friend</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          HTechStudio LLC may offer you the opportunity to invite your contacts from a
          SN Service(such as your Facebook friends) so that those contacts can be located in
          our applications and/or you can invite them to contact you through our application.
          Such contact information will beused only for the purpose of sending communications
          to the addressee. You or the third partymay contact us at support@HTechStudio.com to request the removal
          of this information from our database to the extent HTechStudio LLC stores any of this information.
            </p>

        <h2 style={h2TagStyle} className="display-4">Customer Service</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We may collect your email address when you contact our customer service group
          and we may use that email address to contact you about your gaming experience
          with HTechStudioLLC and notify you about company news and promotions.
          If you no longer wish to receive certain email notifications you may opt-out
          at any time by following the unsubscribe link located at the bottom of each
          communication or by emailing us at support@HTechStudio.
            </p>

        <h2 style={h2TagStyle} className="display-4">Push Notifications</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We may occasionally send you push notifications through our mobile applications to
          send you application updates, high scores and other service related notifications that
          may be of importance to you.  You may at any time opt-out from receiving these types of communications
          by turning them off at the device level through your settings.
            </p>

        <h2 style={h2TagStyle} className="display-4">Application Data Collection</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          Whenever you use our applications, we collect data about all of your interactions with
          the application and with the other users inside the application via server log files.
          This information may be associated with your user ID, IP address or device ID for the
          purpose of providing you our Services and improving them. This data is stored within Firebase.
          With your permission, we will also collect your exact location so that we are able to match you
          with other users in your area.
            </p>

        <h2 style={h2TagStyle} className="display-4">Other Collection</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We may also acquire information from you through (1) your access and participation
          in messageboards on the Service, (2) your participation in surveys regarding the Service
          or (3) your participation in a sweepstakes or contest through the Service.
            </p>
        <p style={pTagStyle}>
          We may provide you the opportunity to participate in a sweepstakes or contest through our Service.
          If you participate, we will request certain personal information from you.
          Participation inthese sweepstakes and contests are voluntary and you therefore have a
          choice whether or notto disclose this information.  The requested information typically
          includes contact information(such as name and shipping address), and demographic information
          (such as zip code).We use this information to notify winners and award prizes, to monitor traffic or
          personalize theService.  We may use a third party service provider to conduct these sweepstakes or
          contests;that company is prohibited from using your users' personal information for any other purpose.
            </p>

        <h2 style={h2TagStyle} className="display-4">Tracking Technologies</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We and our marketing and outsourcing partners, affiliates, or analytics service providers use technologies such as cookies, beacons,
          scripts, and tags to identify a user's computer/device and to "remember" things about your visit, such as your preferences or a user name and
          password.  Information contained in a cookie may be linked to your personal information, such as your user ID, for purposes such as improving the
          quality of our Services, tailoring recommendations to your interests, and making the Service easier to use.  You can disable cookies at any time,
          although you may not be able to access or use features of the Service. We may feature advertisements served by third parties that deliver cookies
          to your computer/device so the content you access and advertisements you see can be tracked. Since the third party advertising companies associate
          your computer/device with a number, they will be able to recognize your computer/device each time they send you an advertisement.  These advertisers
          may use information about your visits to our Service and third party sites and applications in order to measure advertisement performance and to provide
          advertisementsabout goods and services of interest to you. This Policy does not apply to, and we are not responsible for the data collection practices of
          these third party advertisers, and we encourage you to check their privacy policies to learn more about their use of cookies and other technology.
            </p>

        <h2 style={h2TagStyle} className="display-4">Mobile Analytics</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We use mobile analytics software to allow us to better understand the functionality of our Mobile Software on your device.
          This software may record information such as how often you use the application, the events that occur within the application,
          aggregated usage, performance data,and where the application was downloaded from. We do not link the information we store within the analytics
          software to any personal information you submit within the mobile application.
            </p>

        <h2 style={h2TagStyle} className="display-4">Third Party Services</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          Our services may contain third party tracking tools from our service providers, examples ofwhich include Google Analytics and MobileApp Tracking by Tune.
          Such third parties may usecookies, APIs, and SDKs in our services to enable them to collect and analyze user informationon our behalf.
          The third parties may have access to information such as your device identifier, MAC address, IMEI, locale (specific location where a given language is spoken), geo-location
          information, and IP address for the purpose of providing their services under their respective privacy policies. Our privacy policy does not cover the use of tracking tools
          from third parties.We do not have access or control over these third parties.
            </p>

        <h2 style={h2TagStyle} className="display-4">Ad Networks</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We may feature advertising within our Service.  The advertisers may collect and use information about you, such as your Service session activity, device identifier, MAC address, IMEI,
          geo-location information and IP address.  They may use this information to provide advertisements of interest to you.  In addition, you may see our applications advertised in other services.
          After clicking on one of these advertisements and installing our application you will become a user of our Service.  In order to verify the installs, a device identifier may be sharedwith the
          advertiser
            </p>

        <h2 style={h2TagStyle} className="display-4">How We Use Information</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We use information collected through our Service for purposes described in this Policy ordisclosed to you in connection with our Service. For example, we may use your information to:
            </p>
        <ul>
          <li style={liTagStyle}>create applications accounts and allow users to use our application;</li>
          <li style={liTagStyle}>identify and suggest connections with other HTechStudio LLC users;</li>
          <li style={liTagStyle}>operate and improve our Service;</li>
          <li style={liTagStyle}>understand you and your preferences to enhance your experience andenjoyment using our Service;</li>
          <li style={liTagStyle}>respond to your comments and questions and provide customer service;</li>
          <li style={liTagStyle}>provide and deliver products and services you request;</li>
          <li style={liTagStyle}>send you related information, including confirmations, invoices, technical notices,updates, security alerts, and support and administrative messages;</li>
          <li style={liTagStyle}>communicate with you about promotions, rewards, upcoming events, and othernews about products and services offered by HTechStudio LLC and our selected partners;</li>
          <li style={liTagStyle}>enable you to communicate with other users; and</li>
          <li style={liTagStyle}>link or combine it with other information we get from third parties, to helpunderstand your preferences and provide you with better services.</li>
        </ul>

        <h2 style={h2TagStyle} className="display-4">Disclosure of Your information</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          HTechStudio LLC does not share your personal information except as approved by you oras described below:
            </p>
        <ul>
          <li style={liTagStyle}>
            With your consent, for example, when you agree to our sharing your information
            with other third parties for their own marketing purposes subject to their separate privacy policies.
            If you no longer wish to allow us to share your information with third parties, please contact us at support@HTechStudio.com.
            If you no longer wish to receive such communications from third parties, please contact that third party directly.
                </li>
          <li style={liTagStyle}>
            HTechStudio LLC may engage other companies and individuals to performservices on our behalf.
            Example of these services include analyzing data and providing customer support.
            These agents and service providers may have access to your personal information in connection with the
            performance of services for HTechStudio LLC.
                </li>
          <li style={liTagStyle}>
            We may release your information as permitted by law, such as to comply with a subpoena, or when we believe that release is appropriate to comply with the law;
            investigate fraud, respond to a government request, enforce or apply our rights; or protect the rights, property, or safety of us or our users, or others.
            This includes exchanging information with other companies and organizations for fraud protection.
                </li>
          <li style={liTagStyle}>
            HTechStudio LLC may share your information in connection with any merger, sale of our assets, or a financing or acquisition of all or a portion of our
            business to another company.  You will be notified via email and/or notice on our site of any change in ownership or users of your personal information
                </li>
          <li style={liTagStyle}>
            We may share aggregate or anonymous information about you with advertisers, publishers, business partners, sponsors, and other third parties.
                </li>
        </ul>

        <h2 style={h2TagStyle} className="display-4">Changes to the Policy</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We may update this privacy policy to reflect changes to our information practices.
          We encourage you to periodically review this page for the latest information on our privacy practices.
            </p>

        <h2 style={h2TagStyle} className="display-4">Security</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          HTechStudio LLC takes reasonable measures to protect your information from unauthorized access or
          against loss, misuse or alteration by third parties.
            </p>
        <p style={pTagStyle}>
          Although we make good faith efforts to store the information collected on the Service in a secure operating environment that is not available to the public, we cannot guarantee the absolute
          security of that information during its transmission or its storage on our systems.  Further, while we attempt to ensure the integrity and security of our network and systems, we cannot
          guarantee that our security measures will prevent third-party "hackers" from illegally obtaining access to this information.
          We do not warrant or represent that your information will be protected against, loss, misuse, or alteration by third parties.
          No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security.
            </p>

        <h2 style={h2TagStyle} className="display-4">Access to Personal Information</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          If your personal information changes, or if you no longer desire our service, you may correct, update, or delete inaccuracies
          by making the change within your account settings or by contacting us at support@HTechStudio.com.
          We will respond to your access request within 30 days.
            </p>

        <h2 style={h2TagStyle} className="display-4">Data Retention</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We will retain your information for as long as your account is active or as needed to provide youservices.
          If you wish to cancel your account or request that we no longer use your information to provide you services,
          contact us at support@HTechStudio.com.  We will retain and use your information as necessary to comply
          with our legal obligations, resolve disputes, and enforce our agreements.
            </p>

        <h2 style={h2TagStyle} className="display-4">Our Policy Regarding Children</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We do not knowingly collect or solicit personal information from anyone under the age of 13 or knowingly allow
          such persons to use our Service.  If you are under 13, please do not send any information about yourself to us,
          including your name, address, telephone number, or email address.  No one under the age of 13 may provide any personal
          information.  In the event that we learn that we have collected personal information from a child under age 13, we will delete
          that information as quickly as possible.  If you believe that we might have any information fromor about a child under the age of 13,
          please contact us at support@HTechStudio.com.
            </p>

        <h2 style={h2TagStyle} className="display-4">International Transfer</h2>
        <hr className="my-2" />
        <p style={pTagStyle}>
          We may transfer information that we collect about you to affiliated entities,
          or to other thirdparties across borders and from your country or jurisdiction
          to other countries or jurisdictionsaround the world. Please note that these countries
          and jurisdictions may not have the same data protection laws as your own jurisdiction,
          and we take steps to ensure adequate safeguards are in place to enable transfer of information to the U.S. and
          else where and the use and disclosure of information about you, including personal information, as described in this Policy
            </p>

      </Jumbotron>

    </div>
  );
}
