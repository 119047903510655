import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "reactstrap";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionMethods() {

  const classes = useStyles();

  return (
    <div className={classes.section}>
      <h2 className={classes.textCenter}>Support Us</h2>
      <h5>
        We have passion for simple and effective designs and our goal is to create more local developer
        jobs. We will accomplish this by investing back into our local community. Your gift will help us to
        continue our efforts in these areas, help fund our current ongoing projects. Giving is very simple
        and 100% of your gift will be used towards our community goals.
      </h5>
      <h4> To support us, you can click on the <strong>give button below</strong> or you can <strong>Venmo</strong> us driectly <strong>@HTechStudio</strong></h4>

      <br />
      <div className={classes.textCenter}>
        <form action="https://www.paypal.com/donate" method="post" target="_top">
          <input type="hidden" name="hosted_button_id" value="CEYQWVF8PS72Y" />
          <input type="image" style={{ width: "200px" }} src="https://dl.dropbox.com/s/ppv25plqcbz2r71/whitegivebutton.png?dl=0" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>
      {/*<h4> We also accept <strong>Crypto!</strong> You can can send BTC, ETH, LTC, BCH, BAT, or LINK to <strong>kcoin.crypto</strong></h4>
            <h4> If you're  wallet doesn't support .crypto domain addresses, we've inluded some addresses below.</h4>
            */}


    </div>
  );
}
