import React from "react";


import Header from "./Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";


export default function HTHeader({...rest}){
    return(
        <Header
        color="transparent"
        brand="H Tech Studio"
        links={<HeaderLinks dropdownHoverColor="success" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
        {...rest}
      />
    );
}