/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import HTHeader from "components/Header/HTHeader.js";
import HTFooter from "components/Footer/HTFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";

import htHomePageStyle from "assets/jss/material-kit-pro-react/views/htHomePageStyle.js";

// Sections for this page
import SectionIntro from "./Sections/SectionIntro.js";
import SectionProjects from "./Sections/SectionProjects.js";
import SectionTeam from "./Sections/SectionTeam.js";
import SectionPreFooter from "./Sections/SectionPreFooter.js";

const useStyles = makeStyles(htHomePageStyle);

export default function HTHomePage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <HTHeader/>
      <Parallax image={require("assets/img/examples/card-project1.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer style={{marginLeft:100}}>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>H Tech Studio</h1>
              <h4>
                A software design company that partners with people to create solutions 
                that bring ideas to life.
              </h4>

            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionIntro />
          <SectionProjects/>
          <SectionTeam />
          <SectionPreFooter/>
        </div>
      </div>
      <HTFooter/>
    </div>
  );
}
