import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import onehp from "assets/img/1HPGames.png";
import homeearth from "assets/img/HomeEarth.png";
import devscafe from "assets/img/DevsCafe.png";
import shocode from "assets/img/ShoCode.png";
import hcimage from "assets/img/hawaiicardano.png";
import freelance from "assets/img/Freelance2.jpg";

import projectsStyle from "assets/jss/material-kit-pro-react/views/htHomePageSections/projectsStyle.js";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 className={classes.sectionTitle} id="projects">Latest Projects</h2>
        <GridContainer>
          <GridItem md={3} sm={5}>
          <a href="https://www.1hpgames.com" target="_blank">
            <Card blog>
              <CardHeader image>
                  <img src={onehp} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${onehp})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textRose)}
                >
                  Games
                </h6>
                <h4 className={classes.cardTitle}>
                    1HP Games
                </h4>
                <p className={classes.cardDescription} style={{overflow:"hidden", height:"250px"}}>
                  We design many different genres of games and deploy them to a variety 
                  of platforms. These inlcude iOS, Android, MacOS, Windows, Web, Xbox, 
                  Nintendo Switch, and more. 
                </p>
              </CardBody>
            </Card>
            </a>
          </GridItem>
          <GridItem md={3} sm={5}>
          <a href="https://www.hawaiicardano.com" target="_blank">
            <Card blog>
              <CardHeader image>
                  <img src={hcimage} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${hcimage})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textRose)}
                >
                  Cardano Stake Pool
                </h6>
                <h4 className={classes.cardTitle}>
                   Hawaii Cardano
                </h4>
                <p className={classes.cardDescription} style={{overflow:"hidden", height:"250px"}}>
                  We run our own low fee Cardano Staking pools. If you are already invested in cardano, 
                  please consider staking with us. For those of you who don't know anything about Crypto Currencies 
                  and want to learn about it, feel free to contact us.
                </p>
              </CardBody>
            </Card>
            </a>
          </GridItem>
          <GridItem md={3} sm={5}>
          <a href="https://www.fiverr.com/christobear" target="_blank">
            <Card blog>
              <CardHeader image>
                  <img src={freelance} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${freelance})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textRose)}
                >
                  Freelance
                </h6>
                <h4 className={classes.cardTitle}>
                   Fiverr
                </h4>
                <p className={classes.cardDescription} style={{overflow:"hidden", height:"250px"}}>
                  We do accept contract work locally but we've also met with some of our best customers 
                  internationally through Fiverr. Chris is a Seller Level 2 is one 
                  step away from becoming a Top Seller. We will expand our freelancing work to other platforms soon.

                </p>
              </CardBody>
            </Card>
            </a>
          </GridItem>
          <GridItem md={3} sm={5}>
          <a href="https://www.homeearthproject.com" target="_blank">
            <Card blog>
              <CardHeader image>
                  <img src={homeearth} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${homeearth})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textRose)}
                >
                  Environment 
                </h6>
                <h4 className={classes.cardTitle}>
                    Home Earth Project
                </h4>
                <p className={classes.cardDescription} style={{overflow:"hidden", height:"250px"}}>
                  Even though this project has been on hold since the pandemic, we believe it 
                  still has great potential. The overall goal is a social media organization app 
                  that aims to provide easy sign ups and access to event details to volunteers around the world. 
                </p>
              </CardBody>
            </Card>
            </a>
          </GridItem>
          <GridItem md={3} sm={5}>
          <a href="https://www.devscafe.com" target="_blank">
            <Card blog>
              <CardHeader image>
                  <img src={devscafe} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${devscafe})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textRose)}
                >
                  Community
                </h6>
                <h4 className={classes.cardTitle}>
                    Devs Cafe
                </h4>
                <p className={classes.cardDescription} style={{overflow:"hidden", height:"250px"}}>
                  This site was created just for our students(ages 10-17). 
                  Students can display their work. This includes anything from game & web 
                  design to digital art and music.
                </p>
              </CardBody>
            </Card>
            </a>
          </GridItem>
          <GridItem md={3} sm={5}>
          <a href="https://www.shocode.com" target="_blank">
            <Card blog>
              <CardHeader image>
                  <img src={shocode} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${shocode})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textRose)}
                >
                  Teaching
                </h6>
                <h4 className={classes.cardTitle}>
                   shoCode
                </h4>
                <p className={classes.cardDescription} style={{overflow:"hidden", height:"250px"}}>
                  Do you want to learn programming? This site was designed for absolute 
                  beginners. While it isn't 100% complete, this site is often used in our private lessons.
                </p>
              </CardBody>
            </Card>
            </a>
          </GridItem>

        </GridContainer>
        <h3 className={classes.sectionTitle}>And Many More ...</h3>
      </div>
    </div>
  );
}
