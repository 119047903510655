import {
  section,
  container,
  cardTitle,
  grayColor,
  roseColor,
  blackColor,
  coloredShadow
} from "assets/jss/material-kit-pro-react.js";

const projectsStyle = {
  cardTitle,
  container,
  section: {
    ...section,
    padding: "70px 0px",
    color: blackColor
  },
  coloredShadow,
  cardDescription: {
    color: grayColor[3]
  },
  cardCategory: {
    marginTop: "10px"
  },
  textRose: {
    color: roseColor[0] + " !important"
  }
};

export default projectsStyle;
