import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Muted from "components/Typography/Muted.js";
import Button from "components/CustomButtons/Button.js";

import chris from "assets/img/faces/Chris.png";
import corey from "assets/img/faces/Corey.png";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";
import cardProfile6Square from "assets/img/faces/card-profile6-square.jpg";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

const style = {
  ...teamsStyle,
  ...teamStyle,
  justifyContentCenter: {
    justifyContent: "center"
  }
};

const useStyles = makeStyles(style);

export default function SectionTeam() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <h2 className={classes.title} id="team">Meet The Team</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <Card profile plain className={classes.card3}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                  <CardHeader image plain>
                      <img src={chris} alt="..." />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${chris})`,
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <CardBody plain>
                    <h4 className={classes.cardTitle}>Christopher Manloloyo</h4>
                    <Muted>
                      <h6 className={classes.cardCategory}>Co-Founder</h6>
                    </Muted>
                    <p className={classes.description}>
                      Chris has a background in Electrical Engineering and has interest
                      in both hardware and software. He is committed to using software to serve
                      the community.
                    </p>
                  </CardBody>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Card profile plain className={classes.card3}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                  <CardHeader image plain>
                     <img src={corey} alt="..." />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${corey})`,
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <CardBody plain>
                    <h4 className={classes.cardTitle}>Corey Chang</h4>
                    <Muted>
                      <h6 className={classes.cardCategory}>Co-Founder</h6>
                    </Muted>
                    <p className={classes.description}>
                      Corey is an Electrical Enginner and has a passion for 2D/3D art and 
                      Photography. He enjoys creating entertaining games with a simple yet 
                      effective designs.
                    </p>
                  </CardBody>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
        <h3>Thank You For Your Support</h3>
      </div>
    </div>
  );
}
