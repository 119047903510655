import React from "react";
import Favorite from "@material-ui/icons/Favorite";
import Mail from "@material-ui/icons/MailOutline";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";

import Footer from "./Footer.js";

const useStyles = makeStyles(styles);

export default function HTFooter(){
    const classes = useStyles();

    return(
        <Footer
        content={
          <div>
            <div className={classes.leftcustom} style={{display: "inline-block"}}>
            Contact us by Email: {" "}
            <Mail className={classes.icon}/>
              {" "}
              <a
                href="mailto:support@htechstudio.com"
                id="footercontact"
              >
                support@htechstudio.com
              </a>{" "}
              

            </div>
            <div className={classes.right} style={{display: "inline-block"}}>
              &copy; {1900 + new Date().getYear()},
              {" "}
              <a
                href="https://www.htechstudio.com"
              >
                H Tech Studio
              </a>{" "}
              <Favorite className={classes.icon} />

            </div>
          </div>
        }
      />
    );
}