import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Code from "@material-ui/icons/Code";
import People from "@material-ui/icons/People";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import introStyle from "assets/jss/material-kit-pro-react/views/htHomePageSections/introStyle.js";

const useStyles = makeStyles(introStyle);

export default function SectionIntro() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>What We Do</h2>
          <h5 className={classes.description}>
            H Tech Studio is involved in many aspects of digital development. We've worked on 
            designing mobile apps, video games, websites, and even teaching. We are passionate about taking 
            projects that have a positive impact in our local community. This passion naturally led to working 
            with many different collaborators. You can can find information on our latest projects below.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Software"
              description="We design Games and Applications that deploy to a variety of differnt platforms. We always enjoy bringing our own unique ideas to life."
              icon={Code}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Collaborate"
              description="Collaborating with people who have big ideas is one of our passions. It is our mission to help others with any type of software need."
              icon={People}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Contracting"
              description="We have been contracted to work on variety of projects. Freelancing has become a major part of our business and we hope to expand it."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
